/* This file is written in plain CSS because is also used in the Blog build */
.p-icon {
  display: flex;
  width: 40px;
  height: 40px;
}

.p-icon [data-pstroke] {
  fill: none;
  stroke: var(--pcolor-gray-darker);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.p-icon [data-pshape] {
  fill: var(--pcolor-gray-darker);
}
