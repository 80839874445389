.p-button
  display inline-flex
  align-items center
  padding 0 6px
  height 40px
  background-color transparent
  color var(--pcolor-gray-darkest)
  text-decoration none
  line-height 1
  box-shadow 0 0 0 0 var(--pcolor-gray-lighter)
  border 0
  border-radius 4px
  box-sizing border-box
  cursor pointer
  transition all 300ms ease-out
  -webkit-appearance none
  outline 0
  &:focus
    box-shadow 0 0 0 6px var(--pcolor-gray-lighter)
  &:active
    transform scale3d(80%,80%,100%)
  &.p-button-icon
    padding 0
    width 40px
    height 40px
    &:active
      transform scale3d(50%,50%,100%)
  &.p-button-primary,
  &.p-button-secondary,
  &.p-button-outline
    padding 12px
    min-width 160px
    justify-content center
    font-size var(--pfont-size-sm)
    font-weight 700
    border-width 2px
    border-style solid
    border-color transparent
    border-radius 20px
  &.p-button-primary
    background-color var(--pcolor-gray-darkest)
    color var(--pcolor-gray-lightest)
  &.p-button-secondary
    background-color var(--pcolor-gray-lighter)
    color var(--pcolor-gray-darkest)
  &.p-button-outline
    color var(--pcolor-outline-button-text)
    border-color var(--pcolor-gray)
  &.p-button-block
    display flex
    width 100%
