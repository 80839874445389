@import '@mixins'

.p-layout
  position relative
  padding-top 145px
  main
    margin 0 auto
    padding 0 20px
    max-width 1240px
  .p-topbar
    position fixed
    top 0
    left 0
    width 100%

+breakpoint('xs')
  .p-layout
    padding-top 165px
    .p-topbar
      top 20px
      left 50%
      transform translateX(-50%)

+breakpoint('md')
  .p-layout
    padding-top 180px
