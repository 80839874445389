.p-menu-item-active
  position relative
  pointer-events none
  &:after
    position absolute
    bottom 3px
    left 50%
    content ''
    width 3px
    height 3px
    background-color var(--pcolor-gray-darkest)
    border-radius 1.5px
    transform translateX(-50%)
