/* This file is written in plain CSS because is also used in the Blog build */

html,
body {
  margin: 0;
  padding: 0;
  background-color: var(--pcolor-lightest);
  color: var(--pcolor-text);
  font-family: 'Inter', Arial, sans-serif;
  font-weight: var(--pfont-weight-text);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--pcolor-gray-darker);
}

label {
  font-size: var(--pfont-size-xs);
  font-weight: 700;
}

input,
textarea {
  padding: 9.5px;
  background-color: var(--pcolor-gray-lightest);
  color: var(--pcolor-gray-darker);
  font-family: var(--pfont-family-primary);
  font-size: var(--pfont-size-md);
  border: 1px solid var(--pcolor-lightest);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 0 0 0 var(--pcolor-gray-lighter);
  outline: 0;
  transition: all 300ms ease-in-out;
}

input:focus,
textarea:focus {
  box-shadow: 0 0 0 3px var(--pcolor-gray-lighter)
}

input {
  line-height: 1;
}

textarea {
  line-height: 1.2;
}
