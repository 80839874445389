@import '@mixins'

html,
body
  min-height 100vh
  font-size 16px

h1, h2, h3, h4, h5, h6, p
  margin 0
  &+ p
    margin-top 20px

section
  h1
    font-size clamp(var(--pfont-size-xxl), 2.8vw, var(--pfont-size-xxxl))
    line-height 1
    letter-spacing -1px
  h2
    font-size clamp(var(--pfont-size-xxs), 1.7vw, var(--pfont-size-xs))
    text-transform uppercase
    letter-spacing .5px
    word-spacing 5px
  &+ section
    margin-top 80px

p
  margin 0
  font-size clamp(var(--pfont-size-sm), 2.2vw, var(--pfont-size-md))
  line-height 1.2

+breakpoint('md')
  p
    line-height 1.3
