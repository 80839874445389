/* This file is written in plain CSS because is also used in the Blog build */

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url('/assets/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url('/assets/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url('/assets/fonts/Inter-Bold.woff2') format('woff2');
};

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
