.p-locale-select-wrapper
  position: relative;
  width: 40px;
  height: 40px;
  select
    position: relative;
    width: 40px;
    height: 40px;
    background-color: transparent;
    text-indent: -9999px;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    appearance: none;
    cursor: pointer;
    z-index: var(--player-base);
  .p-icon
    position: absolute;
    top: 0;
    left: 0;
